import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Modal } from '@redq/reuse-modal';
import { theme } from 'common/theme/appModern';
import { ResetCSS } from 'common/assets/css/style';
import Sticky from 'react-stickynode';

import Navbar from 'containers/AppModern/Navbar';
import Banner from 'containers/AppMinimal/Banner';
import Footer from 'containers/AppMinimal/Footer';
import Container from 'common/components/UI/Container';


import Pricing from 'containers/WebAppCreative/Pricing';

import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from 'containers/AppModern/appModern.style';
import '@redq/reuse-modal/es/index.css';

import Seo from 'components/seo';

// Lien Strapi : https://buy.stripe.com/test_dR6bJmeKcbM89d6fYY

const Boutique = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Seo title="Cryogenite" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <AppWrapper>
            <Navbar />
          
          <ContentWrapper>
            <Pricing />
          </ContentWrapper>
          <Footer />
        </AppWrapper>
      </>
    </ThemeProvider>
  );
};
export default Boutique;
