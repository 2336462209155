import React, { useState } from 'react';
import { Icon } from 'react-icons-kit';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { ic_keyboard_arrow_right } from 'react-icons-kit/md/ic_keyboard_arrow_right';

import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import CheckBox from 'common/components/Checkbox/index';
import Image from 'common/components/Image';
import Text from 'common/components/Text';
import {
  Section,
  SectionHeading,
  SwitcherWrapper,
  Grid,
  PriceTable,
} from './pricing.style';

const Pricing = () => {
  const data = useStaticQuery(graphql`
    query {
      webAppCreativeJson {
        pricing {
          id
          price {
            monthly
          }
          currencySymbol
          isActive
          title
          desc
          icon {
            publicURL
          }
          button {
            label
            link
          }
        }
      }
    }
  `);

const [checked, setChecked] = useState(false);

const handleChange = () => {
  setChecked(!checked);
};

const { pricing } = data.webAppCreativeJson;

  return (
    <Section id="pricing">
      <Container width="1400px">
        <SectionHeading>
          <Heading content="Commander une prestation" />
        </SectionHeading>
        <Container>
          <p>
            La glace carbonique ne se conserve pas plus de 3 jours. Aussi, nous nous approvisionnons quelques jours avant la prestation et les rendez-vous ne pourront pas être reportés. 
            Lorsque qu’un rendez-vous a été planifié pour une prestation de nettoyage, nous vous demandons de bien vouloir pré-commander et pré-payer la quantité de glace qui vous aura été précisée lors de la réservation.Le rendez-vous vous sera confirmé lorsque nous aurons reçu confirmation de votre commande.
            Tout rendez-vous annulé ou reporté au plus tard 1 semaine avant la date prévue ne pourra faire l’objet du remboursement de la glace (l’approvisionnement étant déjà enclenché).
          </p>
                <CheckBox
                  id="cgu"
                  htmlFor="cgu"
                  labelText="J'accepte les conditions d'utilisation"
                  checked={checked}
                  onChange={handleChange}
                />
            </Container>
        <Grid>
          {pricing.map((priceTable) => (
            <Fade key={priceTable.id} up delay={priceTable.id * 100}>
              <PriceTable
                className={
                  priceTable.isActive
                    ? 'active animate__animated animate__fadeInUp'
                    : 'animate__animated animate__fadeInUp'
                }
              >
              <Heading content={priceTable.title} />
                <Heading as="h5" content={`${priceTable.price.monthly} ${priceTable.currencySymbol}`}/>
                <Text content={priceTable.desc} />
                <div style={!checked ? {cursor: "not-allowed"} : {}}>
                  <a href={priceTable.button.link} target="_blank" style={!checked ? {pointerEvents: "none"}: {}}>
                    <Button disabled={!checked} title={priceTable.button.label} />
                  </a>
                </div>
              </PriceTable>
            </Fade>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default Pricing;
